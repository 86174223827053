import React, { useEffect, useRef } from "react";
import backgroundImage from "./assets/hero_bg3.png"; // Background image
import logoImage from "./assets/logo.png"; // Main logo image

// Carousel logos
import elevenLabs from "./assets/11labs.png";
import qolaba from "./assets/qolaba.svg";
import anthropic from "./assets/anthropic.png";
import leonardo from "./assets/leonardo.png";
import midjourney from "./assets/midjourney.png";
import openai from "./assets/openai.png";
import luma from "./assets/luma.png";

export const FooterSection = ({ showForm, setShowForm }) => {
  const carouselRef = useRef(null);

  // Auto-scroll the carousel
  useEffect(() => {
    let scrollInterval;
    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (carouselRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
          if (scrollLeft + clientWidth >= scrollWidth) {
            carouselRef.current.scrollLeft = 0; // Reset to start
          } else {
            carouselRef.current.scrollLeft += 1;
          }
        }
      }, 20);
    };

    startAutoScroll();

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  const carouselImages = [
    { src: anthropic, alt: "Anthropic Logo" },
    { src: elevenLabs, alt: "Eleven Labs Logo" },
    { src: qolaba, alt: "Qolaba Logo" },
    { src: openai, alt: "OpenAI Logo" },
    { src: leonardo, alt: "Leonardo Logo" },
    { src: midjourney, alt: "Midjourney Logo" },
    { src: luma, alt: "Luma Logo" },
  ];

  return (
    <div
      className="flex flex-col items-center justify-between min-h-screen px-4 py-10 bg-cover text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Top Announcement Bar */}

      {/* Logo Carousel - Full Width with Center Alignment */}
      <div className="w-full overflow-hidden mb-10">
        <div
          className="flex justify-center items-center gap-6"
          ref={carouselRef}
          style={{
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            scrollbarWidth: 'none', // For Firefox
            msOverflowStyle: 'none' // For Internet Explorer and Edge
          }}
        >
          {carouselImages.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={image.alt}
              className="w-24 h-24 object-contain mx-4"
              style={{ flexShrink: 0, minWidth: '6rem', minHeight: '6rem' }} // Prevent shrinking
            />
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className="text-center mb-10">
        <h2 className="text-4xl md:text-5xl font-bold mb-4">
          Get Started with AI Magic.
        </h2>
        <p className="text-lg md:text-xl text-gray-300 mb-6">
          Let Qarrom AI take your content to the next level with our game-changing QCAAS model. Contact us to start your journey today.
        </p>
        <button
          className="px-8 py-3 rounded-full bg-orange-500 hover:bg-orange-600 text-white font-semibold text-lg transition"
          onClick={() => setShowForm(true)}
        >
          Get Started
        </button>
      </div>

      {/* Center Logo and Description */}
      <div className="flex flex-col items-center mb-10">
        <img src={logoImage} alt="Qarrom.ai" className="h-12 mb-2" />
        <h3 className="text-xl font-semibold">Qarrom.ai</h3>
        <p className="text-center text-gray-300 mb-4">
          Discover practical tips and guides to leverage AI in your content creation through our newsletter.
        </p>
        {/* Subscription Form */}
        <div className="flex items-center">
          <input
            type="email"
            placeholder="Enter your email"
            className="px-4 py-2 rounded-l-full border border-gray-300 text-black focus:outline-none"
          />
          <button className="px-6 py-2 rounded-r-full bg-orange-500 hover:bg-orange-600 text-white font-semibold transition">
            Subscribe
          </button>
        </div>
      </div>

      {/* Bottom Navigation Menu */}

      {/* Footer */}
      <div className="text-center mt-4 text-xs text-gray-400">
        Powered by Qarrom AI. ©2024 Qarrom AI. All Rights Reserved.
      </div>
    </div>
  );
};
