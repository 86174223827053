import React, { useState, useRef } from "react";
import BGImage from "./assets/hero_bg3.png";
// Import images
import Image1 from "./assets/promotion.png";
import Image2 from "./assets/brand.png";
import Image3 from "./assets/masterplan.png";
import Image4 from "./assets/image4.png";
import Image5 from "./assets/photo.png";
import Image6 from "./assets/image6.png";

// Import the GetStartedForm component
import GetStartedForm from "./GetStartedForm"; // Adjust the path as necessary

export const PastWorkSection = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isFormVisible, setFormVisible] = useState(false); // State to manage form visibility
  const containerRef = useRef(null);
  const cardRefs = useRef([]);

  const workItems = [
    { 
      imageUrl: Image1, 
      topHeading: "AI for Product Promotion", 
      bottomHeading: "Engage audiences with AI-optimized product photos, tailored perfectly for ads, banners, and social media.", 
      bgColor: "bg-red-500", 
      hoverColor: "hover:bg-red-600" 
    },
    { 
      imageUrl: Image5, 
      topHeading: "AI Virtual Photoshoots", 
      bottomHeading: "Showcase products with AI-generated models and photorealistic shoots—stunning visuals without the setup", 
      bgColor: "bg-orange-500", 
      hoverColor: "hover:bg-orange-600" 
    },
    { 
      imageUrl: Image2, 
      topHeading: "AI for Brand Storytelling", 
      bottomHeading: "Tell your brand’s story visually—AI brings your narrative to life with engaging, on-brand content", 
      bgColor: "bg-gray-700", 
      hoverColor: "hover:bg-gray-800" 
    },
    { 
      imageUrl: Image4, 
      topHeading: "AI for Graphic Design", 
      bottomHeading: "Quickly create eye-catching campaign visuals with AI—speed, affordability, and style for all your design needs.", 
      bgColor: "bg-gray-500", 
      hoverColor: "hover:bg-gray-600" 
    },
    { 
      imageUrl: Image6, 
      topHeading: "AI for Product Design", 
      bottomHeading: "Conceptualize products from every angle in a virtual AI studio—test ideas with ease and save on prototypes", 
      bgColor: "bg-green-500", 
      hoverColor: "hover:bg-green-600" 
    },
    { 
      imageUrl:Image3 , 
      topHeading: "AI for Architecture", 
      bottomHeading: "Visualize architectural spaces in vivid detail, reducing time and costs on complex design processes.", 
      bgColor: "bg-purple-500", 
      hoverColor: "hover:bg-purple-600" 
    },
    // Add more items as needed
  ];
 

  const handleClose = () => {
    setExpandedIndex(null);
  };

  return (
    <div 
      className="flex flex-col items-center justify-center min-h-screen px-4 py-10 text-white"
      style={{ backgroundImage: `url(${BGImage})`, backgroundSize: '100% 100%', backgroundPosition: 'center' }}
    >
      {/* Header */}
      <div className="text-center mb-10">
        <h2 className="text-4xl md:text-5xl font-bold mb-4">AI Photography Services</h2>
        <p className="text-lg md:text-xl text-gray-300">High-quality AI photography—no costly setups, just tailored visuals fast</p>
      </div>

      {/* Work Cards Grid */}
      <div ref={containerRef} className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl w-full relative">
        {workItems.map((item, index) => (
          <div
            key={index}
            ref={(el) => (cardRefs.current[index] = el)}
            className={`relative flex flex-col items-center bg-white rounded-lg overflow-hidden shadow-lg transition-all duration-500 ${expandedIndex === index ? 'absolute z-50' : 'h-80'}`}
          >
            {/* Top Heading */}
            {expandedIndex !== index && (
              <div className="w-full p-4">
                <h3 className="text-center text-black text-lg font-bold">{item.topHeading}</h3>
              </div>
            )}

            {/* Image */}
            <div className="relative w-full h-48 overflow-hidden">
              <img 
                src={item.imageUrl} 
                alt={`Work ${index + 1}`} 
                className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-110" 
              />
            </div>

            {/* Close Button */}
            {expandedIndex === index && (
              <button 
                className="absolute top-4 left-4 bg-black bg-opacity-50 text-white p-2 rounded-full"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                &times;
              </button>
            )}

       
            {/* Description Text */}
            {expandedIndex !== index && (
              <div className="p-4">
                <p className="text-justify text-gray-700 text-sm">{item.bottomHeading}</p>
              </div>
            )}

          </div>
        ))}
      </div>
      <footer className="  text-gray-300 py-6 w-full text-center">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-col items-center justify-center space-y-2 mb-6">
            <p className="text-sm md:text-base max-w-2xl text-center px-4">
              Say goodbye to costly, time-consuming photoshoots—
              <span className="font-semibold text-orange-400">save up to 80%</span> on traditional production costs with our AI-powered solutions.
            </p>
            <p className="text-xs md:text-sm max-w-xl text-center px-4">
              Elevate your brand's visuals effortlessly and affordably!
            </p>
          </div>
        </div>
      </footer>
      <div className="mt-4">
        <button 
          className="w-48 h-12 rounded-full font-semibold shadow-lg bg-orange-500 transition duration-300 hover:bg-orange-600"
          onClick={() => setFormVisible(true)} // Show form on button click
        >
          Get Started
        </button>
      </div>

      {/* Form Component */}
      {isFormVisible && <GetStartedForm onClose={() => setFormVisible(false)} />} {/* Render form conditionally */}
    </div>
  );
};