// App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import { LandingSection } from './LandingSection'; 
import { FooterSection } from './footer';
import { AgencySection } from './agency';
import { PricingSection } from './pricing';
import { PastWorkSection } from './past';
import { PastVideoSection } from './past_video';
import { FAQSection } from './faq';
import { HowItWorks } from './how';
import { PastExtraSection } from './extra_service';
import GetStartedForm from "./GetStartedForm";

function App() {
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (showForm) {
      document.body.style.overflow = 'hidden'; // Prevent body scroll when modal is open
    } else {
      document.body.style.overflow = 'auto'; // Restore body scroll when modal is closed
    }
  }, [showForm]);

  return (
    <div className="App">
      <LandingSection id="landing" showForm={showForm} setShowForm={setShowForm} /> 
      <AgencySection id="benefits" showForm={showForm} setShowForm={setShowForm} />
      <PastVideoSection id="past-video" showForm={showForm} setShowForm={setShowForm} /> 
      <PastWorkSection id="past-work" showForm={showForm} setShowForm={setShowForm} />     
      <PastExtraSection id="past-extra" showForm={showForm} setShowForm={setShowForm} />
      <HowItWorks id="how-it-works" showForm={showForm} setShowForm={setShowForm} />
      <PricingSection id="pricing" showForm={showForm} setShowForm={setShowForm} />
      <FAQSection id="faq" showForm={showForm} setShowForm={setShowForm} />
      <FooterSection id="footer" showForm={showForm} setShowForm={setShowForm} />
      {showForm && <GetStartedForm onClose={() => setShowForm(false)} />}
    </div>
  );
}

export default App;
