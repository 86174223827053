import React, { useState, useEffect, useCallback } from "react";
import BGImage from "./assets/hero_bg3.png"; 

export const PastVideoSection = ({showForm, setShowForm}) => { 
  const [videoClicked, setVideoClicked] = useState(false);

  const videos = [
    { videoUrl: "https://www.youtube.com/watch?v=b-pce94v0aU", topHeading: "Animated Videos", bottomHeading: "Engage audiences with dynamic AI-generated animations that bring your brand’s story to life, customized for every campaign." },
    { videoUrl: "https://www.youtube.com/watch?v=K5fYHXn2oaQ", topHeading: "Video Ads", bottomHeading: "Boost engagement with AI-created video ads, designed to captivate viewers across social platforms and drive results." },
    { videoUrl: "https://www.youtube.com/watch?v=aawPh-F9Op8", topHeading: "Educational & Training Videos", bottomHeading: "Deliver clear, informative content with AI-crafted educational videos, perfect for training or explaining complex topics." },
    { videoUrl: "https://www.youtube.com/watch?v=dTmI7L3DPlI", topHeading: "Product Promotion", bottomHeading: "Showcase product features and benefits with AI-generated demo videos that highlight what makes your offerings unique." },
    { videoUrl: "https://www.youtube.com/watch?v=5dhEbpkoFM0", topHeading: "Social Media Clips", bottomHeading: "Create short, engaging AI-driven video clips perfect for grabbing attention on social media feeds." },
    { videoUrl: "https://www.youtube.com/watch?v=__598QoFHL8", topHeading: "Brand Promotion", bottomHeading: "Enhance brand visibility and recognition with AI-generated videos that embody your brand's identity and message, making a lasting impact on your audience" },
  ];

  const cardStyle = {
    zIndex: 1,
    width: 'calc(20vh * (16 / 9))', // Consistent width for all cards
  
  };

  const handleVideoClick = (iframe) => {
    setVideoClicked(true);
    const newSrc = iframe.src.replace('&mute=1', '&mute=0'); // Unmute the video
    iframe.src = newSrc;
    iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    iframe.requestFullscreen();
  };

  const handleEscapeKey = useCallback((event) => {
    if (event.key === "Escape" && videoClicked) {
      const iframes = document.querySelectorAll('iframe');
      iframes.forEach((iframe) => {
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      });
      setVideoClicked(false);
    }
  }, [videoClicked]);

  useEffect(() => {
    if (videoClicked) {
      window.addEventListener("keydown", handleEscapeKey);
    } else {
      window.removeEventListener("keydown", handleEscapeKey);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [videoClicked, handleEscapeKey]);

  const muteAllVideos = () => {
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      if (!iframe.src.includes('&mute=1')) { // Check if the video is already muted
        const newSrc = iframe.src.replace('&mute=0', '&mute=1'); // Mute the video
        iframe.src = newSrc;
      }
    });
  };

  return (
    <div 
      className="flex flex-col items-center justify-center min-h-screen px-4 py-10 text-white"
      style={{ backgroundImage: `url(${BGImage})`, backgroundSize: '100% 100%', backgroundPosition: 'center' }}
    >
      {/* Header */}
      <div className="text-center mb-4">
        <h2 className="text-4xl md:text-5xl font-bold mb-2">AI Video Creation</h2>
        <p className="text-lg md:text-xl text-gray-300"> From product demos to animated stories and video ads, AI makes video creation seamless and impactful for D2C brands.</p>
      </div>

      {/* Video Stack */}
      <div className="flex flex-wrap justify-center gap-2 mt-4 max-w-6xl w-full">
        {videos.map((video, index) => (
          <div
            key={index}
            className="relative flex flex-col items-center bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105 h-[35vh]"
            style={cardStyle}
            onMouseEnter={muteAllVideos} // Mute all videos on hover
          >
            <h3 className="text-center text-gray-700 text-base font-bold uppercase tracking-wide px-2 mt-2 py-1 rounded">{video.topHeading}</h3>
            <div className="relative w-full h-[15vh] mt-4">
              <iframe
                className="w-full h-full object-contain"
                src={`${video.videoUrl.replace('watch?v=', 'embed/')}?controls=0&showinfo=0&modestbranding=1&rel=0&playsinline=1&mute=1&enablejsapi=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={`Video: ${video.topHeading}`}
              ></iframe>
              <button
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 hover:bg-opacity-40 transition-opacity"
                onClick={(e) => handleVideoClick(e.currentTarget.previousSibling)}
              >
                <svg className="w-12 h-12 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M6.3 2.841A1.5 1.5 0 004 4.11v11.78a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
                </svg>
              </button>
            </div>
            <p className="text-justify px-4 py-4 mt-4 text-xs text-gray-700 mb-2">{video.bottomHeading}</p>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <div className="flex flex-col items-center justify-center space-y-2 mb-6">
          <p className="text-gray-300 text-sm md:text-base max-w-2xl text-center px-4">
            Say goodbye to costly, time-consuming photoshoots—
            <span className="font-semibold text-orange-400">save up to 80%</span> on traditional production costs with our AI-powered solutions.
          </p>
          <p className="text-gray-400 text-xs md:text-sm max-w-xl text-center px-4">
            Elevate your brand's visuals effortlessly and affordably!
          </p>
        </div>
        <button 
          className="w-48 h-12 rounded-full font-semibold shadow-lg bg-orange-500 transition duration-300 hover:bg-orange-600"
          onClick={() => setShowForm(true)} >
          Get Started
        </button>
      </div>
    </div>
  );
};