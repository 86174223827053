import React, { useEffect } from "react";

const GetStartedForm = ({ onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent body scroll when modal is open
    return () => {
      document.body.style.overflow = 'auto'; // Restore body scroll when modal is closed
    };
  }, []);

  return (
    <div style={{
      position: 'fixed',
      top: `${window.scrollY}px`,
      left: 0,
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '500px'
      }}>
        <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Get Started</h2>
        <form>
          <div style={{ marginBottom: '16px' }}>
            <label style={{ display: 'block', color: '#4A4A4A', marginBottom: '4px' }} htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #CCC',
                borderRadius: '4px',
                outline: 'none'
              }}
              placeholder="Your Name"
            />
          </div>
          <div style={{ marginBottom: '16px' }}>
            <label style={{ display: 'block', color: '#4A4A4A', marginBottom: '4px' }} htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #CCC',
                borderRadius: '4px',
                outline: 'none'
              }}
              placeholder="Your Email"
            />
          </div>
          <div style={{ marginBottom: '16px' }}>
            <label style={{ display: 'block', color: '#4A4A4A', marginBottom: '4px' }} htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #CCC',
                borderRadius: '4px',
                outline: 'none'
              }}
              placeholder="Your Phone"
            />
          </div>
          <div style={{ marginBottom: '16px' }}>
            <label style={{ display: 'block', color: '#4A4A4A', marginBottom: '4px' }} htmlFor="company">Company</label>
            <input
              type="text"
              id="company"
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #CCC',
                borderRadius: '4px',
                outline: 'none'
              }}
              placeholder="Your Company"
            />
          </div>
          <div style={{ marginBottom: '16px' }}>
            <label style={{ display: 'block', color: '#4A4A4A', marginBottom: '4px' }} htmlFor="message">Message</label>
            <textarea
              id="message"
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #CCC',
                borderRadius: '4px',
                outline: 'none'
              }}
              placeholder="Your Message"
              rows="4"
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              type="submit"
              style={{
                backgroundColor: '#007BFF',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '4px',
                border: 'none',
                cursor: 'pointer',
                outline: 'none'
              }}
            >
              Submit
            </button>
            <button
              type="button"
              style={{
                color: '#FF0000',
                padding: '10px 20px',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                outline: 'none'
              }}
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GetStartedForm;
