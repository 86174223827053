import React, { useState } from "react"; 
export const FAQSection = () => {
  // State to manage which question is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle the open/close state of each question
  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data
  const faqData = [
    {
      question: "What is QCAAS and how does it differ from traditional content creation services?",
      answer:
        "QCAAS, or Quick Content as a Service, is Qarrom.ai's innovative solution for fast, cost-effective, and high-quality content creation powered by advanced AI technology. Unlike traditional agencies that often require long lead times and come with higher costs, QCAAS delivers compelling content three times cheaper and within 48 hours. Our streamlined process leverages AI to produce customized content tailored to your brand's specific needs, enabling Direct-to-Consumer (D2C) brands to swiftly adapt and dominate their markets.",
    },
    {
      question: "What types of content can I request through QCAAS?",
      answer:
        "QCAAS is designed to handle a wide variety of content types to meet diverse marketing needs. You can request: Advertising Campaigns: Including travel ads, fashion campaigns, and more. Promotional Materials: Such as medical assistance promos and event announcements. Product Demos: Engaging content to showcase your products effectively. Social Media Content: Compelling posts and graphics for various platforms. Email Marketing: Persuasive copy for newsletters and promotional emails. Blog Posts and Articles: Informative and SEO-friendly written content. Our full-stack team ensures that each piece of content aligns with your brand's voice and objectives.",
    },
    {
      question: "How quickly can I receive my content after placing a request?",
      answer:
        "Speed is one of QCAAS's core strengths. All packages offer delivery within 48 hours. We prioritize rapid execution without compromising on quality, ensuring your marketing campaigns remain timely and effective.",
    },
    {
      question: "How does the pricing structure work, and are there any hidden fees?",
      answer:
        "QCAAS offers transparent and straightforward pricing with no hidden fees. We have three main pricing packages to cater to different needs: Content Creation Retainer: Starting at ₹20,000/month Receive high-quality, AI-powered content for your brand. Learn AI with Qarrom: ₹40,000 for one month In-depth, live AI training sessions tailored for your entire team. 3-Month Hybrid Package: Starting at ₹40,000 Combines AI-powered content services with hands-on training for long-term success. All packages are billed on a fixed monthly rate, allowing you to pause or cancel anytime without long-term commitments. This ensures flexibility and cost-efficiency for your business.",
    },
    {
      question: "Can I customize the content to match my brand's unique voice and style?",
      answer:
        "Absolutely! Customization is a key feature of QCAAS. During the fast onboarding process, we take the time to understand your brand’s goals, style, and specific content needs. Our custom-trained AI and experienced content creators ensure that all deliverables are tailored to reflect your unique voice and brand identity. Whether you require formal, casual, or highly specialized content, QCAAS adapts to meet your expectations and maintain consistency across all your marketing materials.",
    },
    {
      question: "What kind of support and revisions are available if I'm not satisfied with the content?",
      answer:
        "Customer satisfaction is our top priority. If you're not completely satisfied with the content delivered, QCAAS offers: Revisions: We provide revisions to refine and enhance the content until it meets your expectations. Dedicated Support: Our support team is available to address any concerns or questions you may have throughout the process. Real-Time Reporting: Track the performance of your content in real time, allowing for ongoing optimization and adjustments. Our goal is to ensure that the content not only meets but exceeds your expectations, driving maximum results for your brand.",
    },
  ];

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-4 py-10  text-white"
     
    >
      {/* Header */}
      <div className="text-center mb-10">
        <h2 className="text-4xl md:text-5xl font-bold mb-4">FAQ</h2>
       
      </div>

      {/* FAQ Accordion */}
      <div className="w-full max-w-2xl space-y-4">
        {faqData.map((item, index) => (
          <div
            key={index}
            className="bg-white bg-opacity-10 rounded-lg overflow-hidden transition-all duration-300"
          >
            <button
              onClick={() => toggleQuestion(index)}
              className="w-full flex justify-between items-center p-4 text-left text-white focus:outline-none"
            >
              <span className="text-lg font-semibold">{item.question}</span>
              <span className="text-2xl">{openIndex === index ? "−" : "+"}</span>
            </button>
            {openIndex === index && (
              <div className="p-4 text-[darkblue] bg-[burlywood] text-left ">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
